<template>
  <v-dialog v-model="emergencyContactDialog" width="600">
    <v-card>
      <v-toolbar flat color="primary">
        <span class="white--text text-h5">{{
          contact.name || "Neuer Notfallkontakt"
        }}</span>
      </v-toolbar>
      <v-card-subtitle class="mt-2">
        Es ist möglich mehrere Notfallkontakte anzulegen. Kontakte für mehrere
        Personen nur dann anlegen, wenn sie die selbe Telefonnummer haben.
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="contactValid"
          @submit.prevent="update()"
          id="contact-form"
          ref="form"
        >
          <v-text-field
            label="Name"
            :rules="nameRules"
            prepend-icon="person"
            v-model="contact.name"
          />
          <v-text-field
            label="Verwandschaftsverhätlinis"
            :rules="relationShipRules"
            prepend-icon="group"
            v-model="contact.relationship"
          />
          <v-text-field
            label="(Mobil-)Telefon"
            :rules="phoneRules"
            hint="Bitte die Telefonnummer mit der besten Erreichbarkeit angeben"
            prepend-icon="phone"
            :append-outer-icon="numberOfPhones === 1 ? 'add_ic_call' : ''"
            @click:append-outer="numberOfPhones++"
            v-model="contact.phone_1"
            info
          />
          <v-expand-transition>
            <v-text-field
              v-if="numberOfPhones > 1"
              label="(Mobil-)Telefon"
              :rules="phoneRules"
              hint="Bitte die Telefonnummer mit der 2. besten Erreichbarkeit angeben"
              prepend-icon="phone"
              :append-outer-icon="numberOfPhones === 2 ? 'add_ic_call' : ''"
              @click:append-outer="numberOfPhones++"
              :append-icon="numberOfPhones === 2 ? 'remove_circle' : ''"
              @click:append="numberOfPhones--"
              v-model="contact.phone_2"
              info
            />
          </v-expand-transition>
          <v-expand-transition>
            <v-text-field
              v-if="numberOfPhones > 2"
              label="(Mobil-)Telefon"
              :rules="phoneRules"
              hint="Bitte die Telefonnummer mit der 3. besten Erreichbarkeit angeben"
              prepend-icon="phone"
              :append-icon="numberOfPhones === 3 ? 'remove_circle' : ''"
              @click:append="numberOfPhones--"
              v-model="contact.phone_3"
              info
            />
          </v-expand-transition>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="emergencyContactDialog = false">
          Schließen
        </v-btn>
        <v-btn
          :disabled="!contactValid"
          color="success"
          type="submit"
          form="contact-form"
        >
          {{ newContact ? "Anlegen" : "Speichern" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EmergencyContactForm",
  data() {
    return {
      newContact: false,
      numberOfPhones: 1,
      contact: {
        name: "",
        phone_1: "",
        phone_2: "",
        phone_3: "",
        relationship: ""
      },
      nameRules: [v => !!v || "Name wird benötigt"],
      addressRules: [v => !!v || "Adresse wird benötigt"],
      phoneRules: [
        v => !!v || "Telefonnummer wird benötigt"
        //v => /[0-9]/.test(v) || "Bitte gebe eine gültige Telefonnummer an" // todo find better regex
      ],
      relationShipRules: [v => !!v || "Verwandtschaftverhältnis wird benötigt"],
      contactValid: false,
      emergencyContactDialog: false
    };
  },
  watch: {
    emergencyContactDialog: function(val) {
      if (!val) {
        this.reset();
      }
    }
  },
  methods: {
    ...mapActions("user", ["newEmergencyContact", "updateEmergencyContact"]),
    reset() {
      this.$refs.form.reset();
    },
    update() {
      let contact = JSON.parse(JSON.stringify(this.contact));
      if (this.newContact) {
        this.newEmergencyContact(contact);
      } else {
        this.updateEmergencyContact(contact);
      }
      this.emergencyContactDialog = false;
    },
    open(newContact, initialContact) {
      this.newContact = newContact;
      if (!this.newContact) {
        this.contact = JSON.parse(JSON.stringify(initialContact));
      }
      this.emergencyContactDialog = true;
    }
  }
};
</script>

<style scoped></style>
