<template>
  <v-overlay v-model="loading">
    <v-progress-circular color="#bf360c" indeterminate size="128" />
    <v-img
      src="../../assets/scouttools_logo.svg"
      aspect-ratio="1"
      max-width="64"
      contain
      class="ma-3"
      style="transform: translate(21px, -108px)"
    />
  </v-overlay>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loading: Boolean
  }
};
</script>

<style scoped></style>
