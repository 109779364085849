<template>
  <v-card flat width="850">
    <v-divider />
    <v-card-subtitle class="text-h6">
      Bankdaten
    </v-card-subtitle>
    <v-card-text>
      <div v-if="!bankAccountValid && !namiBankAccountValid">
        <div class="font-italic my-3">keine Bankdaten vorhanden</div>
      </div>
      <div v-else>
        <v-card
          flat
          rounded
          color="tertiary"
          v-if="namiBankAccountValid"
          class="mb-2"
        >
          <v-card-text>
            <div class="font-weight-medium">Beitragskonto</div>
            <v-row class="mx-0">
              <div class="font-weight-light" style="width: 150px">
                Kontoinhaber:
              </div>
              <div>{{ namiBankAccount.AccountHolder }}</div>
            </v-row>
            <v-row class="mx-0">
              <div class="font-weight-light" style="width: 150px">
                IBAN:
              </div>
              <div>{{ namiBankAccount.IBAN }}</div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          flat
          rounded
          color="tertiary"
          v-if="bankAccountValid"
          class="mb-2"
        >
          <v-card-text>
            <div class="font-weight-medium">Überweisungskonto</div>
            <v-row class="mx-0">
              <div class="font-weight-light" style="width: 150px">
                Kontoinhaber:
              </div>
              <div>{{ bankAccount.AccountHolder }}</div>
            </v-row>
            <v-row class="mx-0">
              <div class="font-weight-light" style="width: 150px">
                IBAN:
              </div>
              <div>{{ bankAccount.IBAN }}</div>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <v-row class="mt-1 mx-0 align-center">
        <v-btn elevation="2" text @click="openBankAccountDialog()">
          {{
            bankAccountValid
              ? "Überweisungskonto ändern"
              : "Überweisungskonto hinterlegen"
          }}
        </v-btn>
        <v-spacer />
        <a @click="bankDataInfoDialog = true">
          <v-icon>info</v-icon>
          Infos zu Bankdaten
        </a>
        <v-dialog v-model="bankDataInfoDialog" width="475">
          <v-card>
            <v-card-title>
              Infos zu Bankdaten
              <v-spacer />
              <v-btn icon @click="bankDataInfoDialog = false">
                <v-icon>
                  close
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text style="text-align: justify">
              Das <b>Beitragskonto</b> ist das Konto, für welches du uns eine
              Einzugsermächtigung für die Mitgliedsbeiträge erteilt hast. Dieses
              Konto kannst du nicht selbstständig ändern. Um es zu ändern,
              schreibe uns bitte eine Email mit den neuen Kontodaten und einer
              neuen Einzugsermächtigung. (<a
                href="mailto:scouttools@dpsg-ketsch.de?subject=Fehlerhafte Personendaten"
                >scouttools@dpsg-ketsch.de</a
              >)
              <br />
              <br />
              Falls du etwas für den Stamm bezahlt hast oder in irgendeiner Form
              der Stamm dir Geld zu überweisen hat, nutzen wir zuerst das
              Beitragskonto, um dir dein Geld zu überweisen. Falls du für diesen
              Fall das Geld lieber auf ein anderes Konto überwiesen hättest,
              kannst du ein <b>Überweisungskonto</b> hinterlegen, welches dann
              fortan für Überweisungen genutzt wird. Das Beitragskonto verändert
              sich dadurch nicht.
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-dialog v-model="bankAccountDialog" width="450">
        <v-card>
          <v-card-title>
            {{
              bankAccountValid
                ? "Überweisungskonto Ändern"
                : "Überweisungskonto Hinterlegen"
            }}
          </v-card-title>
          <v-divider />
          <v-card-subtitle class="mt-1 pr-2">
            Dieses Konto wird für Überweisungen an dich benutzt. Beispielsweise,
            um die von dir vorgelegten Belege zu begleichen. Das Konto für den
            Beitragseinzug ändert sich nicht.
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="bankAccountForm">
              <v-text-field
                v-model="bankAccountFormData.AccountHolder"
                label="Kontoinhaber"
                prepend-icon="person"
                :rules="bankAccountFormDateRules.accountHolder"
              />
              <v-text-field
                v-model="bankAccountFormData.IBAN"
                label="IBAN"
                prepend-icon="account_balance_wallet"
                :rules="bankAccountFormDateRules.IBAN"
              />
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              icon
              @click="deleteBankAccountConfirmDialog = true"
              v-if="bankAccountValid"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn text @click="bankAccountDialog = false">
              Abbrechen
            </v-btn>
            <v-btn color="primary" text @click="saveBankAccount()">
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="deleteBankAccountConfirmDialog" width="310">
          <v-card>
            <v-card-title class="justify-center">
              Bankkonto wirklich löschen?
            </v-card-title>
            <v-card-subtitle class="mt-1">
              Nach dem Löschen, wird falls möglich auf dein Beitragskonto
              zurückgegriffen.
            </v-card-subtitle>
            <v-card-actions>
              <v-btn text @click="deleteBankAccountConfirmDialog = false">
                Abbrechen
              </v-btn>
              <v-spacer />
              <v-btn color="warning" @click="removeBankAccount()">
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import UsersAPI from "@/api/UsersAPI";

export default {
  name: "BankDataPart",
  data() {
    return {
      namiBankAccount: undefined,
      bankAccount: {
        IBAN: "",
        AccountHolder: ""
      },
      bankAccountFormData: {
        IBAN: "",
        AccountHolder: ""
      },
      bankAccountFormDateRules: {
        accountHolder: [],
        IBAN: []
      },
      bankDataInfoDialog: false,
      bankAccountDialog: false,
      deleteBankAccountConfirmDialog: false
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    namiBankAccountValid() {
      return this.validateBankAccount(this.namiBankAccount);
    },
    bankAccountValid() {
      return this.validateBankAccount(this.bankAccount);
    }
  },
  methods: {
    openBankAccountDialog() {
      this.bankAccountFormData = {
        ...this.bankAccountFormData,
        ...this.bankAccount
      };
      this.bankAccountDialog = true;
    },
    saveBankAccount() {
      this.bankAccountFormDateRules = {
        accountHolder: [v => !!v || "Bitte Kontoinhaber angeben"],
        IBAN: [
          v => !!v || "Bitte IBAN angeben",
          v => v.trim().length === 22 || "ungültige Länge für eine IBAN"
        ]
      };
      this.$nextTick(() => {
        //the next tick is needed, to ensure that the rules are populated in the textfields
        if (this.$refs.bankAccountForm.validate()) {
          this.storeBankAccount(this.bankAccount);
          this.bankAccountDialog = false;
          this.bankAccountFormDateRules = {
            accountHolder: [],
            IBAN: []
          };
        }
      });
    },
    removeBankAccount() {
      this.deleteBankAccount();
      this.deleteBankAccountConfirmDialog = false;
      this.bankAccountDialog = false;
    },
    async getFinancialData() {
      await UsersAPI.getFinancialData(this.userInfo.id)
        .then(financialData => {
          this.namiBankAccount = financialData;
        })
        .catch(error => {
          console.log(error);
        });
    },
    validateBankAccount(bankAccount) {
      return !!(
        bankAccount &&
        bankAccount.IBAN &&
        bankAccount.IBAN !== "" &&
        bankAccount.AccountHolder &&
        bankAccount.AccountHolder !== ""
      );
    },
    async getBankAcount() {
      await UsersAPI.getBankAccount(this.userInfo.id)
        .then(bankAccount => {
          if (bankAccount !== "no saved bank account") {
            this.bankAccount = bankAccount;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    async storeBankAccount() {
      await UsersAPI.storeBankAccount(
        this.userInfo.id,
        this.bankAccountFormData
      )
        .then(() => {
          this.bankAccount = this.bankAccountFormData;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async deleteBankAccount() {
      await UsersAPI.deleteBankAccount(this.userInfo.id)
        .then(() => {
          this.bankAccount = {
            IBAN: "",
            AccountHolder: ""
          };
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  async mounted() {
    await this.getBankAcount();
    await this.getFinancialData();
  }
};
</script>

<style scoped></style>
