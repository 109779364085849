<template>
  <v-card flat width="850">
    <v-row class="mx-0 mt-3 justify-center">
      <avatar-picker class="ma-2"></avatar-picker>
    </v-row>
    <v-row class="mx-0 mt-1 justify-center">
      <span class="text-h6">{{
        userInfo.forename + " " + userInfo.surname
      }}</span>
    </v-row>
    <v-divider />
  </v-card>
</template>

<script>
import AvatarPicker from "@/components/user/AvatarPicker";
export default {
  name: "PersonalAvatarPart",
  components: { AvatarPicker },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  }
};
</script>

<style scoped></style>
