<template>
  <v-card flat width="850">
    <v-card-subtitle class="text-h6">
      Persönliche Daten:
    </v-card-subtitle>
    <v-card-text>
      <v-list dense>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>person</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Name:</div>
            {{ userInfo.forename + " " + userInfo.surname }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mail</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Account Email:</div>
            {{ userInfo.accountEmail }}
          </v-list-item-content>
          <v-card-actions>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">info</v-icon>
              </template>
              <div>Diese Email wird für das</div>
              <div>Zurücksetzen des Passworts benutzt.</div>
            </v-tooltip>
          </v-card-actions>
        </v-list-item>
        <v-list-item
          v-if="userInfo.email && userInfo.email !== userInfo.accountEmail"
        >
          <v-list-item-icon>
            <v-icon>email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Email:</div>
            {{ userInfo.email }}
          </v-list-item-content>
          <v-card-actions>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">info</v-icon>
              </template>
              <div>Email gespeichert in NaMi.</div>
              <div>Scouttools benutzt ausschließlich</div>
              <div>deine Account Email.</div>
            </v-tooltip>
          </v-card-actions>
        </v-list-item>
        <v-list-item v-if="userInfo.parentEmail">
          <v-list-item-icon>
            <v-icon>email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">
              Email Vertretungsberechtigter:
            </div>
            {{ userInfo.parentEmail }}
          </v-list-item-content>
          <v-card-actions>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">info</v-icon>
              </template>
              <div>Email gespeichert in NaMi.</div>
              <div>Scouttools benutzt ausschließlich</div>
              <div>deine Account Email.</div>
            </v-tooltip>
          </v-card-actions>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              people_outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Zugriffsrechte Rollen:</div>
            <div class="ml-2 mt-2">
              <v-row>
                <v-chip
                  v-for="role in userInfo.roles"
                  :key="role"
                  class="ml-1 mb-1"
                >
                  {{ role }}
                </v-chip>
              </v-row>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>cake</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Geburtsdatum:</div>
            {{ parseDate(userInfo.birthday) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>place</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Anschrift:</div>
            {{
              userInfo.street + " " + userInfo.postalCode + " " + userInfo.city
            }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>local_phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Telefon:</div>
            {{ parsePhones(userInfo) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>menu_book</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Zeitschriftenversand:</div>
            <div>
              {{
                userInfo.namiMagazineDispatch
                  ? "wird versandt"
                  : "wird nicht versandt"
              }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import Parser from "@/utils/Parser";

export default {
  name: "PersonalInformationPart",
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    ...mapActions("user", ["refreshUserInfo"]),
    parsePhones(user) {
      return Parser.parseTelefonNumber(user.phone1, user.phone2, user.phone3);
    },
    parseDate(date) {
      return Parser.parseDate(new Date(date));
    }
  }
};
</script>

<style scoped></style>
