<template>
  <div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-avatar color="tertiary" size="130" class="px-12">
          <img
            v-if="userInfo.accountAvatarId"
            :src="getAvatarImageUrl"
            alt="Error"
            width="130"
            height="130"
          />
          <div v-else>
            <v-badge color="error" offset-x="10" offset-y="10">
              <span class="font-weight-bold" slot="badge">!</span>
              <v-icon color="white" x-large>
                person
              </v-icon>
            </v-badge>
          </div>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute z-index="4">
              <v-row
                class="justify-center mx-0 px-0"
                :class="{ 'mt-5': userInfo.avatar_image_url }"
              >
                <v-btn small height="35" @click="selectImage()">
                  <span style="white-space: normal;">
                    Profilbild<br />
                    wählen
                  </span>
                </v-btn>
              </v-row>
              <v-row class="justify-center mx-0 px-0 mt-1">
                <v-btn
                  icon
                  small
                  v-if="userInfo.accountAvatarId"
                  @click="deleteAvatar()"
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </v-row>
            </v-overlay>
          </v-fade-transition>
        </v-avatar>
      </template>
    </v-hover>
    <v-dialog
      v-model="cropperDialog"
      width="800"
      persistent
      :class="{ loading: loading }"
    >
      <loader :loading="loading" />
      <v-card min-height="550">
        <v-card-title>
          Profilbild schneiden
        </v-card-title>
        <v-card-actions>
          <img
            :src="selectedImageFilePath"
            @load.stop="showCropper"
            alt
            ref="img"
            style="max-height: 400px;"
          />
        </v-card-actions>
        <v-card-actions v-if="!loading">
          <v-spacer />
          <v-btn class="mr-2" @click="closeDialog()">
            <v-icon>
              clear
            </v-icon>
          </v-btn>
          <v-btn @click="createAvatar()">
            <v-icon>
              check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      accept="image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
      style="display: none"
      ref="input"
      type="file"
    />
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import AccountAPI from "../../api/AccountAPI";
import { mapActions } from "vuex";
import Loader from "@/components/layout/Loader";

export default {
  name: "AvatarPicker",
  components: { Loader },
  data() {
    return {
      loading: false,
      selectedImageFilePath: "",
      avatarFilePath: "",
      cropperDialog: false,
      cropper: undefined,
      cropperOptions: {
        aspectRatio: 1,
        autoCropArea: 1,
        viewMode: 1,
        movable: false,
        zoomable: false
      },
      filename: "",
      mimeType: ""
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    getAvatarImageUrl() {
      let baseUrl = process.env.VUE_APP_USER_API_BASE_URL;
      return baseUrl + this.userInfo.accountAvatarId;
    }
  },
  methods: {
    ...mapActions("user", ["insertUserInfo", "deleteAvatar"]),
    ...mapActions("snackbar", ["showSnackbar"]),
    selectImage() {
      this.$refs.input.click();
    },
    showCropper() {
      this.createCropper().then(() => {
        this.loading = false;
      });
    },
    createCropper() {
      return new Promise(resolve => {
        this.cropper = new Cropper(this.$refs.img, this.cropperOptions);
        resolve();
      });
    },
    getRoundedCanvas(sourceCanvas) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var width = sourceCanvas.width;
      var height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = "destination-in";
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
      );
      context.fill();
      return canvas;
    },
    compress(sourceCanvas) {
      let canvas = document.createElement("canvas");
      const size = 640;
      canvas.width = size;
      canvas.height = size;
      let context = canvas.getContext("2d");
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, size, size);
      return canvas;
    },
    createAvatar() {
      let croppedCanvas = this.cropper.getCroppedCanvas();
      let compressed = this.compress(croppedCanvas);
      compressed.toBlob(blob => {
        let avatar = new File([blob], this.filename);
        let vueI = this;
        AccountAPI.uploadAvatar(avatar)
          .then(response => {
            vueI.insertUserInfo(response.data);
            vueI.showSnackbar({
              text: "Avatar angepasst."
            });
          })
          .catch(() => {
            vueI.showSnackbar({
              text:
                "Bei der Anpassung des Avatars ist ein Fehler aufgetreten.\n",
              color: "error"
            });
          });
      });
      this.closeDialog();
    },
    deleteAvatar() {
      let vueI = this;
      AccountAPI.deleteAvatar()
        .then(response => {
          vueI.insertUserInfo(response.data);
          vueI.showSnackbar({
            text: "Avatar gelöscht."
          });
        })
        .catch(() => {
          vueI.showSnackbar({
            text: "Beim Löschen des Avatars ist ein Fehler aufgetreten.\n",
            color: "error"
          });
        });
    },
    closeDialog() {
      this.selectedImageFilePath = "";
      this.cropper.destroy();
      this.cropper = undefined;
      this.cropperDialog = false;
    }
  },
  mounted() {
    let fileInput = this.$refs.input;
    fileInput.addEventListener("change", () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        let reader = new FileReader();
        reader.onload = e => {
          this.selectedImageFilePath = e.target.result;
        };
        reader.readAsDataURL(fileInput.files[0]);
        this.filename = fileInput.files[0].name;
        this.mimeType = fileInput.files[0].type;
        this.$emit("changed", fileInput.files[0], reader);

        //open loader after file selection
        this.loading = true;
        this.cropperDialog = true;
      }
    });
  }
};
</script>

<style>
.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}
</style>
<style scoped>
.loading {
  /*visibility: hidden;*/
  /*display: none;*/
  opacity: 0;
}
</style>
