<template>
  <v-card flat width="850" min-height="200">
    <v-divider v-if="topDivider" />
    <v-card-subtitle>
      <span class="text-h6">Notfallkontakte</span>
      <v-btn
        color="tertiary"
        class="ml-3 mt-n2"
        fab
        x-small
        elevation="1"
        @click="openEmergencyContactForm(true, {})"
      >
        <v-icon>
          person_add
        </v-icon>
      </v-btn>
    </v-card-subtitle>
    <v-row
      class="justify-center mt-10"
      v-if="
        !userInfo.emergencyContacts || userInfo.emergencyContacts.length === 0
      "
    >
      <v-icon class="mr-1">
        contacts
      </v-icon>
      Keine Notfallkontakte vorhanden
    </v-row>
    <v-row class="px-2 pt-4 pb-2 mx-0 justify-center justify-md-start">
      <emergency-contact
        class="mx-2 mb-2"
        v-for="contact in userInfo.emergencyContacts"
        :contact="contact"
        :open-emergency-edit-dialog="openEmergencyContactForm"
        :key="contact.id"
      />
    </v-row>
    <emergency-contact-form ref="emergencyContactForm" />
  </v-card>
</template>

<script>
import EmergencyContactForm from "@/components/user/EmergencyContactForm";
import EmergencyContact from "@/components/user/EmergencyContact";
export default {
  name: "EmergencyContactsManagerPart",
  components: { EmergencyContact, EmergencyContactForm },
  props: {
    topDivider: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    openEmergencyContactForm(newContact, contact) {
      this.$refs.emergencyContactForm.open(newContact, contact);
    }
  }
};
</script>

<style scoped></style>
