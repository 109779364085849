<template>
  <div>
    <v-row class="mx-0 justify-center">
      <personal-avatar-part />
    </v-row>
    <v-row class="mx-0 justify-center">
      <personal-information-part />
    </v-row>
    <v-row class="mx-0 justify-center">
      <bank-data-part ref="bank-data" />
    </v-row>
    <v-row class="justify-center mx-0">
      <emergency-contacts-manager-part />
    </v-row>
    <v-row class="mx-0 justify-center">
      <v-card flat width="850">
        <v-divider />
        <v-card-subtitle class="text-h6">
          Pfadfinder Werdegang
        </v-card-subtitle>
        <v-card-text>
          <scouting-history :own="true" />
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mx-0 justify-center">
      <v-alert text type="info" border="left" width="828">
        <div>
          Sind manche persönlichen Informationen über dich nicht korrekt oder
          fehlen?
        </div>
        <div>
          Schreibe eine Mail an
          <a
            href="mailto:scouttools@dpsg-ketsch.de?subject=Fehlerhafte Personendaten"
            >scouttools@dpsg-ketsch.de</a
          >
          und wir korrigieren sie!
        </div>
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ScoutingHistory from "@/components/user/ScoutingHistory";
import PersonalAvatarPart from "@/components/personal/PersonalAvatarPart";
import PersonalInformationPart from "@/components/personal/PersonalInformationPart";
import EmergencyContactsManagerPart from "@/components/personal/EmergencyContactsManagerPart";
import BankDataPart from "@/components/personal/BankDataPart";

export default {
  name: "Personal.vue",
  components: {
    BankDataPart,
    EmergencyContactsManagerPart,
    PersonalInformationPart,
    PersonalAvatarPart,
    ScoutingHistory
  },
  methods: {
    ...mapActions("user", ["refreshUserInfo"])
  },
  mounted() {
    this.refreshUserInfo();
    console.log(this.$route.query.target);
    let scrolltarget = this.$refs[this.$route.query.target];
    if (scrolltarget) {
      this.$vuetify.goTo(scrolltarget);
    }
  }
};
</script>

<style scoped></style>
